import React, { useEffect, useRef, useState } from 'react';
import { GA_SHOP_BY_CATEGORY, GTM_HEADER } from 'helpers/constants/aafes';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import Hamburger from '../../../icons/hamburger';
import MegaMenuArrow from '../../../images/megamenu-arrow.png';
import MegaMenu from './megaMenu';
function MenuHamburger({
  menuData,
  leftColumn,
  rightColumn,
  findStore,
  militaryStarData,
  militaryStarApply,
  militaryStarParentURL,
  setStoresList,
  storeList,
  searchInput,
  setSearchInput,
  setIsScrollOnPLPCLP,
  isPreviewDate,
  islargeDesktop,
  additionalPadding,
  setResetDefaultStore,
}) {
  const { formatMessage } = useFormat({ name: 'common' });
  const [closeMenu, setcloseMenu] = useState(false);
  const [mobileMenu, setmobileMenu] = useState(false);
  const btnRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnRef.current && !btnRef.current.contains(event.target)) {
        setcloseMenu(false);
      }
    };
    const handleWindowClick = (event) => {
      handleClickOutside(event);
    };
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);
  function handlePageScroll(property) {
    document.body.style.overflowY = property;
    document.body.style.position = property === 'hidden' ? 'fixed' : 'static';
  }
  useEffect(() => {
    if (mobileMenu) {
      handlePageScroll('hidden');
    } else {
      handlePageScroll('visible');
    }
  }, [mobileMenu]);

  useEffect(() => {
    setIsScrollOnPLPCLP(false);
  }, [closeMenu]);

  return (
    <>
      <section
        className="relative z-10 order-1 flex items-center text-black-900 md:order-2 md:hidden"
        onClick={() => setmobileMenu(!mobileMenu)}
      >
        {' '}
        <button
          onClick={() => {
            setmobileMenu(true);
          }}
          type="button"
          className="flex items-center"
          aria-label={formatMessage({ id: 'shopByCategory', defaultMessage: 'Shop by category' })}
          aria-haspopup="true"
          aria-expanded="false"
        >
          {' '}
          <Hamburger className="mr-2 cursor-pointer" />{' '}
          <span className="hidden text-sm font-semibold text-blue-900 xl:flex">
            {formatMessage({ id: 'shop', defaultMessage: 'Shop' })}
          </span>
        </button>
      </section>
      <section
        className="relative z-10 order-1 hidden items-center text-black-900 md:order-2 md:flex"
        onClick={() => setcloseMenu(!closeMenu)}
      >
        {' '}
        <button
          ref={btnRef}
          onClick={() => {
            setcloseMenu(true);
          }}
          type="button"
          className="flex items-center"
          aria-label={formatMessage({ id: 'shopByCategory', defaultMessage: 'Shop by category' })}
          aria-haspopup="true"
          aria-expanded="false"
          data-gtm-menu
          data-gtm-id={GTM_HEADER}
          data-gtm-name={GA_SHOP_BY_CATEGORY}
        >
          {' '}
          <Hamburger className="mr-2 cursor-pointer" />{' '}
          <span className="hidden text-sm font-semibold text-blue-900 xl:flex">
            {formatMessage({ id: 'shop', defaultMessage: 'Shop' })}
          </span>
        </button>
        {closeMenu && (
          <section className="boxArrow absolute left-7 top-8 z-50 md:-left-[4px] md:block">
            <img src={MegaMenuArrow.src} alt="Mega Menu Drop Down Icon" className="w-[24px]" />
          </section>
        )}
      </section>

      {(closeMenu || mobileMenu) && (
        <MegaMenu
          megaMenu={menuData}
          setcloseMenu={setcloseMenu}
          leftColumn={leftColumn}
          rightColumn={rightColumn}
          findStore={findStore}
          militaryStarData={militaryStarData}
          militaryStarApply={militaryStarApply}
          militaryStarParentURL={militaryStarParentURL}
          setmobileMenu={setmobileMenu}
          setStoresList={setStoresList}
          storeList={storeList}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          isPreviewDate={isPreviewDate}
          islargeDesktop={islargeDesktop}
          additionalPadding={additionalPadding}
          setResetDefaultStore={setResetDefaultStore}
        />
      )}
    </>
  );
}

export default MenuHamburger;
