import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  MEGAMENU_CMS_APPDISPLAY,
  MEGAMENU_CMS_L1_CONFIG,
  MEGAMENU_CMS_PATH_EXTENSION,
  MEGAMENU_CMS_WEBDISPLAY,
} from 'helpers/constants/aafes';
import { fetchApiHub, revalidateOptions, useCart } from 'frontastic';
import Accordion from './accordion';
import MobileAccordion from './mobileAccordion';

function MegaMenu({
  megaMenu,
  setcloseMenu,
  leftColumn,
  rightColumn,
  findStore,
  militaryStarData,
  militaryStarApply,
  militaryStarParentURL,
  setmobileMenu,
  setStoresList,
  storeList,
  searchInput,
  setSearchInput,
  isPreviewDate,
  islargeDesktop,
  additionalPadding,
  setResetDefaultStore,
}) {
  const { studioConfig } = useCart();
  const [megaMenuCMSBasePath, setMegaMenuCMSBasePath] = useState(null);
  const [megaMenuCategorySuffix, setMegaMenuCategorySuffix] = useState(null);
  useEffect(() => {
    if (studioConfig?.megaMenuCMSBasePath) {
      try {
        const megaMenuCMSKey = studioConfig?.megaMenuCMSBasePath;
        setMegaMenuCMSBasePath(megaMenuCMSKey);
      } catch (error) {
        console.error('Error Parsing megaMenuCMSTemlateKey:', error);
      }
    }
    if (studioConfig?.megaMenuCategorySuffix) {
      try {
        const megaMenuCategorySuffix = studioConfig?.megaMenuCategorySuffix;
        setMegaMenuCategorySuffix(megaMenuCategorySuffix);
      } catch (error) {
        console.error('Error Parsing megaMenuCMSTemlateKey:', error);
      }
    }
  }, [studioConfig]);
  const formatCategoryName = (name: string) => {
    return name.toLowerCase().replace(/[, &]/g, '-').replace(/--+/g, '-').replace(/^-|-$/g, '');
  };
  const menuCategories = megaMenu?.categoryData?.dataSource?.root?.children;
  const megaMenuAssetpathObject: Record<string, string> = menuCategories?.reduce((acc, item) => {
    const formattedCategoryName = formatCategoryName(item?.categoryName || '');
    const categorySuffix = megaMenuCategorySuffix && megaMenuCategorySuffix?.replace('SUFFIX', formattedCategoryName);
    acc[item?.categoryName] = `${megaMenuCMSBasePath}${categorySuffix}${MEGAMENU_CMS_PATH_EXTENSION}`;
    return acc;
  }, {});
  const formattedArray = Object?.values(megaMenuAssetpathObject)
    ?.map((item) => encodeURIComponent(item))
    ?.join('%2C');

  const { data } = useSWR(
    megaMenuCMSBasePath ? `/action/cms/getTemplates?assetPath=${encodeURIComponent(formattedArray)}` : null,
    fetchApiHub,
    revalidateOptions,
  );
  // Generate asset paths for CMS Mega Menu Categories
  const generateAssetPaths = (menuCategories) => {
    return menuCategories?.reduce((acc, item) => {
      const categoryName = item?.categoryName || '';
      const formattedName = formatCategoryName(categoryName);
      acc[
        categoryName
      ] = `${megaMenuCMSBasePath}${formattedName}/${MEGAMENU_CMS_L1_CONFIG}${MEGAMENU_CMS_PATH_EXTENSION}`;
      return acc;
    }, {});
  };

  // Fetch CMS menu categories data
  const useFetchMenuCategories = (menuCategories) => {
    const assetPaths = generateAssetPaths(menuCategories);
    const formattedCategoryArray = Object.values(assetPaths).map(encodeURIComponent).join('%2C');
    return useSWR(
      megaMenuCMSBasePath ? `/action/cms/getTemplates?assetPath=${formattedCategoryArray}` : null,
      fetchApiHub,
      revalidateOptions,
    );
  };

  // Parse CMS menu categories
  const parseMenuCategories = (categories) => {
    if (!Array.isArray(categories)) return [];
    return categories.map((item) => {
      const content =
        typeof item?.content === 'string' && item?.content.trim() !== '' ? JSON.parse(item?.content) : null;

      return {
        assetPath: item?.assetPath,
        content,
      };
    });
  };

  // Extract category data
  const extractCategoryData = (categories) => {
    if (!Array.isArray(categories)) return [];

    return categories
      .map((category) => {
        let categoryName = null;
        let channelDisplayState = null;

        Object.values(category?.content || {}).forEach((items) => {
          if (Array.isArray(items)) {
            items.forEach((item) => {
              if (!categoryName && Array.isArray(item?.children)) {
                const child = item.children.find((child) => child.label === 'title' && child.value);
                if (child) categoryName = child.value;
              }
              if (!channelDisplayState && item?.channelDisplayState) {
                channelDisplayState = item.channelDisplayState;
              }
            });
          }
        });
        return categoryName
          ? {
              categoryName,
              channelDisplayState,
            }
          : null;
      })
      .filter(Boolean);
  };
  const filterMenuCategories = (menuCategories, cmsCategoriesData) => {
    if (!Array.isArray(menuCategories) || !Array.isArray(cmsCategoriesData)) {
      return [];
    }
    const categoryMap = cmsCategoriesData.reduce((map, item) => {
      if (item.categoryName) {
        const normalizedCategoryName = normalizeCategoryName(item.categoryName);
        map[normalizedCategoryName] = item;
      }
      return map;
    }, {});
    return menuCategories.filter((menuCategory) => {
      const menuCategoryName = menuCategory?.categoryName.toLowerCase();
      const cmsCategory = categoryMap[menuCategoryName];
      if (!cmsCategory) {
        return true;
      }
      const displayStates = cmsCategory.channelDisplayState.split(',').map((state) => state.trim());

      if (displayStates.includes(MEGAMENU_CMS_WEBDISPLAY)) {
        return true;
      }
      if (displayStates.includes(MEGAMENU_CMS_APPDISPLAY) && displayStates.includes(MEGAMENU_CMS_WEBDISPLAY)) {
        return true;
      }
      if (displayStates.includes(MEGAMENU_CMS_APPDISPLAY) && !displayStates.includes(MEGAMENU_CMS_WEBDISPLAY)) {
        return false;
      }
      return true;
    });
  };

  const normalizeCategoryName = (categoryName) => {
    if (!categoryName) return '';
    return categoryName
      .replace(/&amp;/g, '&') // Replace 'amp;' with '&'
      .toLowerCase(); // Convert to lowercase
  };
  const useProcessMenuCategories = (menuCategories) => {
    const { data: cmsMenuCategories } = useFetchMenuCategories(menuCategories);
    const [filteredCategories, setFilteredCategories] = useState([]);

    useEffect(() => {
      if (cmsMenuCategories) {
        const parsedCategories = parseMenuCategories(cmsMenuCategories);
        const cmsCategoriesData = extractCategoryData(parsedCategories);
        const filtered = filterMenuCategories(menuCategories, cmsCategoriesData);
        setFilteredCategories(filtered);
      }
    }, [cmsMenuCategories, menuCategories]);

    return filteredCategories;
  };
  const filteredMenuCategories = useProcessMenuCategories(menuCategories);
  return (
    <>
      <section
        className={`z-11 absolute hidden h-[100vw] w-[calc(100%_-_50px)] max-w-[calc(1440px_-_50px)] md:block ${
          isPreviewDate && (islargeDesktop || additionalPadding) ? 'top-[112px]' : 'top-[66px]'
        }`}
      >
        <section className="block md:grid md:grid-cols-5">
          <Accordion
            menuCategories={filteredMenuCategories}
            closeMenu={setcloseMenu}
            megaMenuAssetpathObject={megaMenuAssetpathObject}
            templates={data}
          />
        </section>
      </section>

      <section className="fixed left-0 top-0 z-20 block h-[100vh] w-11/12 overflow-y-auto bg-white md:hidden">
        <section
          className="fixed left-0 top-0 z-10 h-[100vh] w-full bg-black-900 opacity-70"
          onClick={() => setmobileMenu(false)}
        ></section>
        <MobileAccordion
          menuCategories={filteredMenuCategories}
          leftColumn={leftColumn}
          rightColumn={rightColumn}
          findStore={findStore}
          closeMenu={setmobileMenu}
          militaryStarData={militaryStarData}
          militaryStarApply={militaryStarApply}
          militaryStarParentURL={militaryStarParentURL}
          setStoresList={setStoresList}
          storeList={storeList}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setResetDefaultStore={setResetDefaultStore}
          megaMenuAssetpathObject={megaMenuAssetpathObject}
          templates={data}
        />
      </section>
    </>
  );
}

export default MegaMenu;
